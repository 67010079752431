<template>
    <div>
        <st-modal ref='modal'
                hide-header-delimiter
                no-close-on-backdrop
                no-close-on-esc
                hide-footer
                hideable
                hide-header-close
                size="md"
                :title="$t('APPLICATION.CONFIRM_SIGN_MODAL.TITLE')"
                :hideHeaderClose="isConfirmationLoading"
                customClass="form-modal"
                :id="modalId"
                @modalClosed="hide"
        >
            <template #body>
                <form class="form"
                    novalidate="novalidate"
                    id="st_modal_confirm_sign_form"
                >
                    <div class="row pb-6">
                        <div class="col">
                            <p class="application-confirmation-text" v-html="text"></p>
                            <div class="form-group">
                                <st-input-text
                                    :ref="fields.pin.name"
                                    :name="fields.pin.name"
                                    v-model="model[fields.pin.name]"
                                    set-validation
                                    :max-length="6"
                                    @input="resetConfirmationStatus"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <span v-if="confirmationStatus === 'invalid_pin'" class="text-danger">{{ $t('APPLICATION.SIGN_CONFIRMATION.INVALID_PIN') }}</span>
                            <span v-if="confirmationStatus === 'error'" class="text-danger">{{ $t('APPLICATION.SIGN_CONFIRMATION.ERROR') }}</span>
                            <span v-if="confirmationStatus === 'pin_expired'" class="text-danger">{{ $t('APPLICATION.SIGN_CONFIRMATION.PIN_EXPIRED') }}</span>
                            <span v-if="confirmationStatus === 'session_expired'" class="text-danger">{{ $t('APPLICATION.SIGN_CONFIRMATION.SESSION_EXPIRED') }}</span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <st-button
                            size="large"
                            variant="secondary"
                            :callback="hide"
                            :disabled="isConfirmationLoading"
                        > {{ $t('GENERAL.BUTTON.CANCEL') }}
                        </st-button>
                        <st-button
                            size="large"
                            variant="primary"
                            :callback="doSubmit"
                            :spinner="isConfirmationLoading"
                            :disabled="isConfirmationLoading"
                        >
                            <span>{{ $t('GENERAL.BUTTON.SEND_CODE') }}</span>
                        </st-button>
                    </div>
                </form>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ApplicationModel } from "@/modules/applications/models/application-model";
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { generateRandomId } from '@/core/helpers/globalMethods';

const { fields } =  ApplicationModel;

const formSchema = new FormSchema([
   fields.pin,
]);

export default {
    inheritAttrs: false,
    name: 'ConfirmSignModal',
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        review: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields,
            isValidationAttached: false,
            fv: null,
            modalId: `st-modal-confirm-sign-${generateRandomId()}`,
            polling: null,
            confirmationStatus: '',
            isConfirmationLoading: false,
        }
    },
    methods: {
        ...mapActions({
            doValidateDocumentSign: 'applications/form/validateDocumentSign',
            doValidateDocumentSignCitizen: 'applications/form/validateDocumentSignCitizen',
            doUpdate: 'applications/form/update',
            checkSigningStatus: 'applications/form/checkSigningStatus',
            activateLoading: 'shared/setActiveLoading',
            inactivateLoading: 'shared/setInactiveLoading'
        }),
        show() {
            this.model = formSchema.initialValues({});
            this.$refs['modal'].show();
        },
        hide() {
            this.confirmationStatus = '';
            this.clearPollingScraps();
            this.$refs['modal'].hide();
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const payload = {
                    applicationId: this.applicationId,
                    pin: this.model.pin,
                    review: this.review,
                }
                try {
                    this.isConfirmationLoading = true;
                    this.activateLoading('applications/signValidatedAndApply');
                    const response = await this.doValidateDocumentSignCitizen(payload);
                    switch(response) {
                        case 'error':
                            this.confirmationStatus = response;
                            this.isConfirmationLoading = false;
                            this.inactivateLoading('applications/signValidatedAndApply');
                            break;
                        case 'pending':
                            this.polling = setInterval(() => {
                                this.checkSignConfirmation(this.applicationId);
                            }, 5000); // TO DO: Decrease interval to 1000
                            break;
                        default:
                    }
                } catch(error) {
                    this.isConfirmationLoading = false;
                    this.inactivateLoading('applications/signValidatedAndApply');
                }
            }
        },
        async checkSignConfirmation(id) {
            try {
                const status = await this.checkSigningStatus(id);
                if (status !== 'pending') {
                    this.confirmationStatus = status;
                    this.isConfirmationLoading = false;
                    clearInterval(this.polling);
                }
                if (status !== 'pending' && status !== 'successful') {
                    this.inactivateLoading('applications/signValidatedAndApply');
                }
                if (status === 'successful') {
                    this.$emit('signValidated');
                    this.hide();
                }
            } catch(error) {
                this.confirmationStatus = 'error';
                this.isConfirmationLoading = false;
                this.inactivateLoading('applications/signValidatedAndApply');
                clearInterval(this.polling);
            }
        },
        resetConfirmationStatus() {
            if (this.confirmationStatus) {
                this.confirmationStatus = '';
            }
        },
        clearPollingScraps() {
            if (this.polling) {
                clearInterval(this.polling);
            }
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
            currentApplication: 'applications/form/record',
        }),
        cancelText() {
            return this.$t('GENERAL.BUTTON.CANCEL');
        },
        submitText() {
            return this.$t('GENERAL.BUTTON.SAVE');
        },
        text() {
            return this.$t("APPLICATION.CONFIRM_SIGN_MODAL.MESSAGE_SENT", [ this.currentUser.phone_number ] );
        },
    },
    created() {
        this.model = formSchema.initialValues({});
    },
    mounted() {
        this.$root.$on('bv::modal::shown', (modalObj) => {
            if (modalObj.componentId === this.modalId) {
                this.fv = createFormValidation(this.modalId, this.rules);
            }
        });
    },
    beforeDestroy () {
        this.clearPollingScraps();
    },
}
</script>
